/* eslint-disable @next/next/no-img-element */
import { Badge, DateRender, ImgResponsive } from '@/components/elements'
import classNames from 'classnames'
import React from 'react'
import { FaRegCalendar } from 'react-icons/fa6'
import { StyleCardFeaturedArticles } from './styles'
import Link from 'next/link'

interface CardFeaturedArticlesProps {
  slug: string
  categories?: any[]
  title: string
  featuredImage?: string
  date: string
  isHihtlight?: boolean
  rootClassName?: string
  author?: string
  avatarImg?: string
}

const CardFeaturedArticles = (props: CardFeaturedArticlesProps) => {
  const {
    slug,
    categories,
    title,
    featuredImage,
    date,
    isHihtlight,
    rootClassName,
    author,
    avatarImg,
  } = props
  const featuredCls = classNames(
    'flex flex-col border border-gray-100 bg-white',
    {
      'p-2': !isHihtlight,
    },
    rootClassName,
  )

  return (
    <>
      {!isHihtlight ? (
        <StyleCardFeaturedArticles className={featuredCls}>
          {featuredImage ? (
            <Link href={`/${slug}`} className='block'>
              <ImgResponsive
                className='aspect-[3/2] w-full object-cover'
                src={featuredImage}
                alt={title}
                loadingLazy
              />
            </Link>
          ) : null}
          <div className={classNames('mt-4 flex flex-1 flex-col gap-4')}>
            <div className='flex-1'>
              <span className='small'>
                {categories?.map((category: any) => (
                  <Badge
                    key={category?.node?.slug ?? category?.name}
                    content={category?.node?.name ?? category?.name}
                    shape='square'
                    styles={{
                      backgroundColor: '#1990FF',
                      borderColor: '#1990FF',
                      color: '#fff',
                    }}
                  />
                ))}
              </span>
              <h4 className='mt-2 line-clamp-2'>
                <Link href={`/${slug}`} className='block'>
                  {title}
                </Link>
              </h4>
            </div>
            <div className='small flex-none'>
              <div
                className={classNames('meta-date flex items-center gap-2 text-[#97989F]', {
                  'bottom-0': !isHihtlight,
                  'bottom-4': isHihtlight,
                })}
              >
                <FaRegCalendar />
                <DateRender type='long' date={date} />
              </div>
            </div>
          </div>
        </StyleCardFeaturedArticles>
      ) : (
        <article
          className={classNames('flex aspect-[3/2] flex-col justify-end', {
            'lg:col-span-3': isHihtlight,
          })}
          style={{
            backgroundImage: `linear-gradient(4deg,rgba(0,0,0,.8) 16.24%,rgba(0,0,0,0) 74.25%),url(${featuredImage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div className='space-y-2 p-4'>
            {categories?.map((category: any) => (
              <Badge
                key={category?.node?.slug ?? category?.name}
                content={category?.node?.name ?? category?.name}
                shape='square'
                styles={{
                  backgroundColor: '#1990FF',
                  color: '#fff',
                  borderColor: '#1990FF',
                }}
              />
            ))}
            <h4 className='line-clamp-2 text-lg font-semibold text-white'>
              <Link href={`/${slug}`}>{title}</Link>
            </h4>
            <div className='flex flex-row gap-2 text-sm/relaxed font-normal text-white/95'>
              {isHihtlight && avatarImg && (
                <div className='flex items-center gap-2'>
                  <span className='sr-only'>Author</span>
                  <img src={avatarImg} className='max-h-10 max-w-10 rounded-full' alt={author} />
                  {author}
                </div>
              )}
              <div
                className={classNames('meta-date flex items-center gap-2', {
                  'bottom-0': !isHihtlight,
                  'bottom-4': isHihtlight,
                })}
              >
                <FaRegCalendar className='text-lg' />
                <DateRender type='long' date={date} />
              </div>
            </div>
          </div>
        </article>
      )}
    </>
  )
}

export default CardFeaturedArticles
