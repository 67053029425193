import styled from 'styled-components'

export const StyleSectionHeaderService = styled.div`
  overflow: hidden;
  position: relative;

  .bg-service-top {
    padding-top: 60px;
    padding-bottom: 20px;
    @media only screen and (min-width: 768px) {
      padding-top: 70px;
      padding-bottom: 50px;
    }
  }
  .head-content__title {
    color: #fff;
    font-size: 48px;
    line-height: 77px;
    @media only screen and (min-width: 768px) {
      font-size: 88px;
      line-height: 140px;
    }
    @media only screen and (min-width: 1024px) {
      font-size: 64px;
      line-height: 110px;
    }
    @media only screen and (min-width: 1536px) {
      font-size: 84px;
      line-height: 140px;
    }
  }
  p {
    @media only screen and (min-width: 768px) {
      font-size: 18px;
      line-height: normal;
    }
  }
  .bg-service-bottom {
    background-image: url('/images/service/bg-service-brand.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left bottom;
  }
`
