import React from 'react'
import Link from 'next/link'
import { ImgResponsive } from '@/components/elements'
import { FaArrowRight } from 'react-icons/fa6'
import { StylePortfolioItem } from './style'
import classNames from 'classnames'

type PortfolioItemProps = {
  title?: string | React.ReactNode
  categories?: string
  serviceImg?: string
  serviceBg?: string
  noLink?: boolean
  className?: string
  slug?: string
}

const PortfolioItem = (props: PortfolioItemProps) => {
  const {
    title = '',
    categories = '',
    serviceImg = '',
    serviceBg = '',
    noLink,
    className,
    slug,
  } = props
  const mergeCls = classNames('p-4', className)
  return (
    <StylePortfolioItem className={mergeCls} serviceBg={`${serviceBg}`}>
      <div className='flex h-full flex-col space-y-4'>
        <div className='grow'>
          <ImgResponsive
            src={serviceImg}
            alt={categories}
            width={355}
            height={250}
            PicClassName='flex justify-center items-end h-full'
          />
        </div>
        <div className='content text-center'>
          <h3 className='text-xl'>{title}</h3>
          <p className='small uppercase tracking-[3.15px] md:tracking-[7.15px]'>{categories}</p>
        </div>
        {noLink ? (
          <div className='flex-none'>&nbsp;</div>
        ) : (
          <div className='flex flex-none justify-end'>
            <Link href={slug ?? `/portfolio/`} className='service-readmore text-black'>
              <FaArrowRight size={14} />
            </Link>
          </div>
        )}
      </div>
    </StylePortfolioItem>
  )
}

export default PortfolioItem
