import React from 'react'
import Link from 'next/link'
import { DateRender, ImgResponsive } from '@/components/elements'
import classNames from 'classnames'
import { Categories } from '@/types'

type FeaturedArticleProps = {
  rootClassName?: string
  featuredImage?: string
  title: string
  slug: string
  date: string
  categories?: Categories
  excerpt?: string
  readMore?: boolean
}

const FeaturedArticle = (props: FeaturedArticleProps) => {
  const { rootClassName, featuredImage, title, slug, date, categories, excerpt, readMore } = props
  const featuredCls = classNames('featured-image', rootClassName)
  return (
    <div className={featuredCls}>
      {featuredImage ? (
        <Link href={`/${slug}`}>
          <ImgResponsive
            className='w-full'
            src={featuredImage}
            alt={title}
            loadingLazy
            style={{
              aspectRatio: 87 / 49,
            }}
          />
        </Link>
      ) : null}
      <div className='py-4 md:flex md:flex-col md:justify-between'>
        <span className='small text-content-base'>
          <DateRender type='long' date={date} />
          {categories &&
            categories?.edges.length > 0 &&
            categories?.edges?.map((category: any) => (
              <span className='badge badge-primary mx-2' key={category?.node?.slug}>
                {category?.node?.name}
              </span>
            ))}
        </span>
        <h4 className='mb-4 text-lg font-semibold md:line-clamp-1'>
          <Link href={`/${slug}`}>{title}</Link>
        </h4>
        {excerpt && (
          <div
            dangerouslySetInnerHTML={{ __html: excerpt }}
            className='line-clamp-2 text-sm text-content-base'
          />
        )}
        {readMore && (
          <Link className='mt-4 font-semibold' href={`/${slug}`}>
            อ่านทั้งหมด
          </Link>
        )}
      </div>
    </div>
  )
}

export default FeaturedArticle
