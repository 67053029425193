import styled from 'styled-components'

export const StyleServiceItem = styled.div<{ serviceBg: string }>`
  border: 0;
  border-radius: 0;
  box-shadow:
    1px 0 0 0 #c7d1d9,
    0 1px 0 0 #c7d1d9,
    1px 1px 0 0 #c7d1d9,
    1px 0 0 0 #c7d1d9 inset,
    0 1px 0 0 #c7d1d9 inset;
  transition: all 0.2s;
  height: 100%;
  .service-readmore {
    color: #000000;
  }
  &:hover {
    background-color: ${(props) => (props.serviceBg ? props.serviceBg : '#fff')};
    box-shadow:
      0px 0 0 0 #c7d1d9,
      0 0px 0 0 #c7d1d9,
      0px 0px 0 0 #c7d1d9,
      0px 0 0 0 #c7d1d9 inset,
      0 0px 0 0 #c7d1d9 inset;
    .title-inner {
      h2 {
        color: #fff !important;
      }
    }
    .service-readmore {
      color: #fff;
    }
  }
  .title-inner {
    > .prologue {
      font-size: 40px;
      font-weight: 600;
      transform: translateY(-60%);
      @media only screen and (min-width: 768px) {
        font-size: 70px;
      }
      @media only screen and (min-width: 992px) {
        font-size: 140px;
      }
      &.prologue-center {
        left: 50%;
        transform: translate(-50%, -75%);
      }
    }
    > .subtitle {
      font-size: 28px;
      @media only screen and (min-width: 992px) {
        font-size: 38px;
      }
      &-top {
        font-size: 28px;
        @media only screen and (min-width: 992px) {
          font-size: 38px;
        }
      }
    }
    > .title {
      font-size: 38px;
      z-index: 1;
      @media only screen and (min-width: 768px) {
        font-size: 40px !important;
      }
      @media only screen and (min-width: 992px) {
        font-size: 40px !important;
      }
      @media only screen and (min-width: 1280px) {
        font-size: 40px !important;
      }
      @media only screen and (min-width: 1440px) {
        font-size: 40px !important;
      }
    }
  }
  &.align-prologue-center {
    .title-inner {
      > .prologue {
        top: 22px;
      }
    }
  }
`
