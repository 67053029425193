import React from 'react'
import { Typography } from '@/components/elements'
import { StyleListBullet } from './style'
import classNames from 'classnames'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import Link from 'next/link'
import { FaAnglesRight } from 'react-icons/fa6'

type ListBulletProps = {
  list: string[]
  listStyle?: 'column' | 'row'
  className?: string
  txtLink?: string
  linkOutPage?: string
}

gsap.registerPlugin(ScrollTrigger)

const ListBullet = (props: ListBulletProps) => {
  const { list, className, listStyle = 'row', txtLink, linkOutPage } = props
  const megaWrapper = classNames(
    'list',
    {
      // eslint-disable-next-line prettier/prettier
      grid: listStyle === 'column',
      'list-row': listStyle === 'row',
    },
    className,
  )
  return (
    <>
      <StyleListBullet className={megaWrapper}>
        {list.map((item) => (
          <Typography tag='li' key={item} className='list-bullet'>
            {item}
          </Typography>
        ))}
      </StyleListBullet>
      {linkOutPage && (
        <Link className='flex items-center gap-2 text-primary hover:underline' href={linkOutPage}>
          {txtLink} <FaAnglesRight />
        </Link>
      )}
    </>
  )
}

export default ListBullet
