/* eslint-disable @next/next/no-img-element */
import React from 'react'
import { ImgResponsive } from '@/components/elements'
import { StyleCustomerLogo } from './style'
import classNames from 'classnames'

type CustomerLogoProps = {
  imgSrc: string
  imgAlt: string
  imgSrcHover: string
  className?: string
}

const CustomerLogo = (props: CustomerLogoProps) => {
  const { imgSrc, imgAlt, imgSrcHover, className } = props
  const meageWrapper = classNames('customer-image', className)
  return (
    <StyleCustomerLogo>
      <span className={meageWrapper}>
        <span className='customer-link'>
          <span className='customer-logo'>
            <ImgResponsive width={62} height={74} src={imgSrc} alt={imgAlt} loadingLazy />
          </span>
          <span className='customer-hover-logo'>
            <ImgResponsive width={62} height={74} src={imgSrcHover} alt={imgAlt} loadingLazy />
          </span>
        </span>
      </span>
    </StyleCustomerLogo>
  )
}

export default CustomerLogo
